import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import * as cn from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import * as styles from "./header.module.scss"
import * as logo from "../assets/Texas_logo_white_bright_2.svg"
import * as logoSymbol from "../assets/Texas_logo_symbol_bright_2.svg"
import useOutsideClick from "../hooks/click-outside"
import { PHONE_NUMBER } from "../consts/contact-data"
import { SRLWrapper } from "simple-react-lightbox"

import pfr from "../assets/pfr.jpg";

const options = {
  overlayColor: "rgba(15,15,15,0.95)",
  buttonsBackgroundColor: "transparent",
  buttonsIconColor: "#852D19",
  showCaption: false,
  showDownloadButton: false,
  showThumbnails: false,
  autoplaySpeed: 1500,
  enablePanzoom: false,
}

const Header = ({ siteTitle }) => {
  const [menuVisible, menuToggle] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    menuVisible && menuToggle(false)
  })

  return (
    <>
      <nav
        className={cn(styles.fixedSidebar, styles.sidebarLeft, {
          [styles.fixedSidebarOpen]: menuVisible,
        })}
      >
        <div className={cn(styles.headerContainer)} ref={ref}>
          <nav className={styles.menuFixed}>
            <ul>
              <li>
                <AnchorLink to="/#home" title="Start">
                  Start
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#order" title="Order online">
                  Zamów online
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#about" title="O nas">
                  O nas
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#gallery" title="Galeria">
                  Galeria
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#contact" title="Kontakt">
                  Kontakt
                </AnchorLink>
              </li>
            </ul>
          </nav>
          <div className={styles.footerSidebar}>
            <SRLWrapper options={options}>
              <a
                href={pfr}
                data-attribute="SRL"
              >
                <img src={pfr} />
              </a>
            </SRLWrapper>
            <p>
              <span>Zamówienia</span>
            </p>
            <h6>{PHONE_NUMBER}</h6>
          </div>
        </div>
      </nav>

      <div
        className={cn(styles.burgerMenu, { [styles.menuOpen]: menuVisible })}
        onClick={() => menuToggle(!menuVisible)}
      >
        <div
          className={cn(styles.lineMenu, styles.lineHalf, styles.firstLine)}
        />
        <div className={styles.lineMenu} />
        <div
          className={cn(styles.lineMenu, styles.lineHalf, styles.lastLine)}
        />
      </div>
      <div className={styles.fixedSidebarBgOverlay} />
      <div className={styles.sideContent}>
        <div className={styles.logo}>
          <img src={logoSymbol} className="img-fluid" alt="" />
          <AnchorLink to="/#home" title="Start">
            <img src={logo} className="img-fluid" alt="" />
          </AnchorLink>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
