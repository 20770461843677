import React from "react"

import * as styles from "./footer.module.scss"

const Footer = ({ siteTitle }) => (
  <footer className={styles.footer}>
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className={styles.social}>
            <a
              href="https://facebook.com/TexasBarRestaurant/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="ti-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/texas_bar_restaurant"
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="ti-instagram"></i>
            </a>
          </div>
          <p>
            <small>© 2020 {siteTitle}. All right reserved.</small>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
