/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent, { Cookies } from "react-cookie-consent"
import Header from "./header"
import SimpleReactLightbox from "simple-react-lightbox"

import * as styles from "./layout.module.scss"
import Footer from "./footer"
import { Button } from "../components/button"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <SimpleReactLightbox>
        <Header siteTitle={data.site.siteMetadata.title} />
      </SimpleReactLightbox>
      <SimpleReactLightbox>
        <main className={styles.content}>{children}</main>
      </SimpleReactLightbox>
      <Footer siteTitle={data.site.siteMetadata.title} />
      {typeof window !== "undefined" && CookieConsent && (
        <CookieConsent
          location="bottom"
          buttonText="Akceptuję"
          cookieName="accept_cookies"
          style={{
            background: "#0f0f0f",
            boxShadow: "0 -1px 2px 2px rgba(0,0,0,0.2)"
          }}
          expires={150}
          buttonWrapperClasses={styles.cookiesButton}
        >
          <h6 className={styles.cookies}>
            Ta strona wykorzystuje pliki cookie
          </h6>
          <p className={styles.cookiesText}>
            Używamy informacji zapisanych za pomocą plików cookies w celu
            zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Mogą
            też korzystać z nich współpracujące z nami firmy badawcze oraz
            reklamowe. Jeżeli wyrażasz zgodę na zapisywanie informacji zawartej
            w cookies kliknij w przycisk po prawej stronie tej informacji. Jeśli
            nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz
            zmienić w swojej przeglądarce.
          </p>
        </CookieConsent>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
